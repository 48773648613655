import { FaFacebookSquare } from 'react-icons/fa';
import DiscordLogo from './images/discord-logo.png';

const Footer = () => {
  return (
    <footer className="mt-40 mb-20 flex flex-col items-center">
      {/* <h3 className="font-bold text-2xl mb-4">Contact us</h3> */}
      <a href="https://discord.gg/xhH3fWkP" target="_blank" rel="noopener noreferrer" className="flex items-center mb-2">
        <img className="w-8 mr-2" src={DiscordLogo} alt="Discord log" />
        <p className="text-gray-600 font-bold">Discord</p>
      </a>
      <a href="https://www.facebook.com/lambify" target="_blank" rel="noopener noreferrer" className="flex items-center mb-2">
        <FaFacebookSquare className="w-8 mr-1" color="#4B5563" size="32" />
        <p className="text-gray-600 font-bold">Lambify</p>
      </a>

      <p className="text-gray-600">
        Email: parnpresso@gmail.com
      </p>
    </footer>
  );
}
export default Footer;
