import { Route, Router } from 'react-router';
import { createBrowserHistory } from 'history';

import Homepage from './pages/Homepage';
import BuildLayer from './pages/BuildLayer';
import Docs from './pages/Docs';
import LayerDetail from './pages/LayerDetail';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Route path='/build-a-layer' component={BuildLayer} exact />
      <Route path='/layers/:id' component={LayerDetail} exact />
      <Route path='/docs' component={Docs} exact />
      <Route path='/' component={Homepage} exact />
    </Router>
  );
}

export default App;
