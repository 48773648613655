import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import Skeleton from "react-loading-skeleton";
import { FaRegSave, FaRegCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Footer from "../../components/footer";
import Header from "../../components/header";
import { API_URL } from "../../config/app";
import { formatRuntime } from '../../utils/LayerFormat';
import LoadingAnimation from './animations/63609-cofee-time.json';
import PythonLogo from './images/python-logo.png';

interface Package {
  name: string;
  version: string;
}

interface Build {
  id: string;
  runtime: string;
  packages: Package[];
  name: string;
  build_status: 'QUEUED' | 'BUILDING' | 'PUBLISHED';
  created_at: string;
  url_s3: string;
  url_s3_file: string;
  url_arn: string;
}

const LayerDetail = () => {
  let { id } = useParams() as any;

  const data = {
    id: '',
    runtime: '',
    packages: [],
    name: '',
    build_status: 'QUEUED',
    created_at: '',
    url_s3: '',
    url_s3_file: '',
    url_arn: ''
  } as Build;
  const [buildData, setBuildData] = useState<Build>(data);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${API_URL}/builds/${id}`)
      .then(response => response.json())
      .then(data => {
        setBuildData(data);
        setIsLoading(false);
      });
  }, [id]);

  return (
    <div className="container mx-auto px-4 md:px-8">
      <Header />

      <div className="grid grid-cols-6 gap-4 md:mt-8">
        <div className="col-span-6 md:col-span-4">
          {isLoading ? (
            <Skeleton count={1} height={100} />
          ) : (
            <div className="bg-white p-4 md:p-8 rounded-xl mb-4 flex flex-row justify-between">
              <div className="flex flex-row items-center ">
                <div className="flex justify-center items-center bg-yellow-100 rounded-full p-4 md:w-20 md:h-20 text-2xl">
                  {buildData.runtime.includes('python') ? (
                    <img className="w-8" src={PythonLogo} alt="python logo" />
                  ) : (null)}
                </div>
                <div className="flex flex-col justify-start pl-4">
                  <h1 className="text-xl md:text-3xl font-bold mb-1">{buildData.name}</h1>
                  <p className="text-xs md:text-sm text-gray-400 leading-none md:leading-normal"><span>Layer ID:</span> {buildData.id}</p>
                </div>
              </div>
              <div className="hidden md:flex">
                <p className="text-sm font-bold text-gray-400">Created at: {new Date(buildData.created_at || '').toLocaleString()}</p>
              </div>
            </div>
          )}

          {isLoading ? (
            <Skeleton count={1} height={100} />
          ) : (
            <div className="bg-white p-8 rounded-xl">
              <h2 className="text-lg font-bold">Runtime</h2>
              <p className="mb-8">{formatRuntime(buildData.runtime || '')}</p>

              <h2 className="text-lg font-bold">Packages</h2>
              <ul className="list-disc list-inside">
                {buildData.packages?.map((item, index) => (
                  <li className="underline" key={`package-${index}`}>
                    <a
                      href={`https://pypi.org/pypi/${item.name}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name} {item.version ? (`(${item.version})`) : (null)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="col-span-6 md:col-span-2">
          {isLoading ? (
            <Skeleton count={1} height={100} />
          ) : (
            <div className="flex flex-col items-center justify-center bg-white w-full p-8 pt-0 rounded-xl">
              {buildData.build_status === 'PUBLISHED' ? (
                <div className="flex flex-col items-center p-8 w-full">
                  <h2 className="font-bold text-2xl">Let's use the layer</h2>
                  <Link to="/docs" className="text-gray-600 mb-8 underline">Read more on how to use</Link>

                  {buildData.url_s3 ? (
                    <CopyToClipboard text={buildData.url_s3_file}>
                      <button className="font-semibold flex items-center justify-center border-black rounded-full border-2 px-4 py-2 mb-2 w-56 cursor-pointer hover:border-yellow-600 hover:text-white hover:bg-yellow-600 outline-none focus:outline-none">
                        <FaRegCopy className="mr-1" />Copy S3 url
                      </button>
                    </CopyToClipboard>
                  ) : (null)}

                  {buildData.url_arn ? (
                    <CopyToClipboard text={buildData.url_arn}>
                      <button className="font-semibold flex items-center justify-center border-black rounded-full border-2 px-4 py-2 mb-2 w-56 cursor-pointer hover:border-yellow-600 hover:text-white hover:bg-yellow-600 outline-none focus:outline-none">
                        <FaRegCopy className="mr-1" />Copy ARN url
                      </button>
                    </CopyToClipboard>
                  ) : (null)}

                  {buildData.url_s3_file ? (
                    <CopyToClipboard text={buildData.url_s3_file}>
                      <a
                        className="font-semibold flex items-center cursor-pointer hover:text-yellow-600"
                        href={buildData.url_s3_file}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="font-semibold flex items-center justify-center border-black rounded-full border-2 px-4 py-2 mb-2 w-56 cursor-pointer hover:border-yellow-600 hover:text-white hover:bg-yellow-600 outline-none focus:outline-none">
                          <FaRegSave className="mr-1" />Download a layer
                        </button>
                      </a>
                    </CopyToClipboard>
                  ) : (null)}
                </div>
              ) : (
                <>
                  <Lottie
                    className="w-56"
                    animationData={LoadingAnimation}
                  />
                  <h4 className="font-bold text-gray-600">
                    {buildData.build_status === 'QUEUED' ? 'Wait in a queue, stay chill' : ''}
                    {buildData.build_status === 'BUILDING' ? 'Almost there! We are building a layer' : ''}
                  </h4>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default LayerDetail;
