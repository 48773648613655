import { useEffect, useState } from "react";

import { FaRegSave, FaRegCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from "react-loading-skeleton";
import { Link } from 'react-router-dom';

import Header from '../../components/header';
import Footer from "../../components/footer";
import { formatRuntime } from '../../utils/LayerFormat';
import PythonLogo from './images/python-logo.png';

interface Package {
  name: string;
  version: string;
}
interface Layer {
  id: string;
  name: string;
  runtime: string;
  url_arn: string;
  url_s3: string;
  url_s3_file: string;
  created_at: string;
  packages: Package[];
}

const LayerCard = (layer: Layer) => {
  const { name, runtime, url_arn, url_s3_file, created_at } = layer;
  const createdAt = new Date(created_at).toLocaleString();

  return (
    <div className="mb-2" key={`layer=${layer.id}`}>
      <div className="flex md:hidden flex-row justify-between rounded-xl py-2 px-4 bg-white mb-2">
        <Link className="flex py-2 cursor-pointer" to={`layers/${layer.id}`}>
          <div className="flex justify-center items-center bg-yellow-100 rounded-full w-12 h-12 text-2xl">
            {runtime === 'python3.8' ? (
              <img className="w-8 p-1" src={PythonLogo} alt="python logo" />
            ) : (null)}
          </div>
          <div className="flex flex-col justify-center pl-4">
            <h4 className="font-bold text-lg">{name}</h4>
            <h4 className="text-gray-400 text-sm leading-none">{formatRuntime(runtime)}</h4>
          </div>
        </Link>
      </div>

      <div className="hidden md:flex flex-row justify-between rounded-xl py-4 px-8 bg-white mb-2">
        <Link className="flex py-2 cursor-pointer" to={`layers/${layer.id}`}>
          <div className="flex justify-center items-center bg-yellow-100 rounded-full w-16 h-16 text-2xl">
            {runtime.includes('python') ? (
              <img className="w-8 p-1" src={PythonLogo} alt="python logo" />
            ) : (null)}
          </div>
          <div className="flex flex-col justify-center pl-4">
            <h4 className="font-bold text-xl">{name}</h4>
            <h4 className="text-gray-400">{formatRuntime(runtime)}</h4>
          </div>
        </Link>
        <div className="flex flex-col items-end justify-between">
          <div>
            <p className="text-gray-400 text-sm">{createdAt}</p>
          </div>
          <div className="flex flex-row items-end">
            <CopyToClipboard text={url_arn}>
              <p className="font-semibold flex items-center cursor-pointer hover:text-yellow-600">
                <FaRegCopy className="mr-1" />ARN
              </p>
            </CopyToClipboard>
            <p className="text-gray-800 mx-2">|</p>
            <CopyToClipboard text={url_s3_file}>
              <p className="font-semibold flex items-center cursor-pointer hover:text-yellow-600">
                <FaRegCopy className="mr-1" />S3
              </p>
            </CopyToClipboard>
            <p className="text-gray-800 mx-2">|</p>
            <a
              className="font-semibold flex items-center cursor-pointer hover:text-yellow-600"
              href={url_s3_file}
              target="_blank"
              rel="noreferrer"
            >
              <FaRegSave className="mr-1" />Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const Homepage = () => {
  const [layers, setLayers] = useState<Layer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getLayers().then(data => {
      setLayers(data);
      setIsLoading(false);
    });
  }, []);

  const getLayers = async () => {
    const response = await fetch('https://eu1x9m3d60.execute-api.ap-southeast-1.amazonaws.com/prod/layers');
    const data = await response.json();
    return data;
  };

  return (
    <div className="w-full">
      <div className="flex justify-center items-center bg-yellow-100 w-full p-4">
        <p className="text-gray-600 leading-tight text-center">
          <span className="font-bold">🎉 Updated!</span> Lambify is now supported build for Python 3.8, 3.7, 3.6, and 2.7 🎉
        </p>
      </div>
      <div className="container mx-auto px-4 md:px-8">
        <Header />

        <div>
          <div className="flex flex-row justify-between rounded-md bg-gray-100 w-full h-80 mb-12 px-16 py-12">
            <div className="flex flex-col justify-between">
              <a
                href="https://www.producthunt.com/posts/lambify?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lambify"
                target="_blank"
                rel="noopener noreferrer"
                className="hidden md:flex"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299850&theme=dark"
                  alt="Lambify - Build your AWS Lambda Layer in a few clicks | Product Hunt"
                  style={{ width: '250px', height: '54px' }}
                  width="250"
                  height="54"
                />
              </a>
              <div>
                <h1 className="font-bold text-2xl text-gray-500 mb-1 text-center md:text-left">Explore and build</h1>
                <h1 className="flex flex-col md:flex-row font-bold text-6xl text-center md:text-left"><div className="text-yellow-600 mr-2">AWS Lambda</div> Layers</h1>
              </div>
            </div>
            <div className="hidden md:flex flex-col justify-center">
              {layers.length !== 0 ? (
                <div className="flex flex-col items-center bg-white rounded-md p-8 shadow-xl">
                  <h1 className="font-bold text-6xl text-yellow-600 mb-2">{layers.length}</h1>
                  <h3 className="text-gray-600 font-bold">Total Layers</h3>
                </div>
              ) : (null)}
            </div>
          </div>

          <div className="mb-8">
            <h1 className="font-bold text-2xl md:text-3ml mb-4">🐍 Python Layers</h1>
            {isLoading ? (
              <Skeleton count={3} height={50} />
            ) : (null)}
            {layers.map(layer => LayerCard(layer))}
          </div>

        </div>

        <Footer />
      </div>
    </div>
  );
}
export default Homepage;
