import Footer from "../../components/footer"
import Header from "../../components/header"

import ARNImage from './images/lambda-add-arn.png';
import LambifyARNImage from './images/lambify-arn.png';
import AWSAttachLayer from './images/attach-layer-to-function.png';
import AWSLayers from './images/layers.png';
import AWSCreateNewLayer from './images/create-new-layer.png';

const Documentation = () => {
  return (
    <div className="container mx-auto px-8">
      <Header />

      <div className="grid grid-cols-6 gap-4 mt-8">
        <div className="hidden md:block md:col-span-1 underline">
          Getting started
        </div>

        <div className="col-span-6 md:col-span-5">
          <h1 className="text-4xl font-bold mb-2">Getting started with Lambify.</h1>
          <h4 className="text-gray-500 mb-8">There are 2 ways to use AWS Lambda Layer from Lambilfy.</h4>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-16">
            <div className="bg-green-500 rounded h-40 p-4 cursor-pointer">
              <h2 className="font-bold text-white text-xl mb-1">Create new layer in your AWS</h2>
              <h4 className="text-white">Use Lambify S3 url or Download a file</h4>
            </div>
            <div className="bg-blue-500 rounded h-40 p-4 cursor-pointer">
              <h2 className="font-bold text-white text-xl mb-1">Add Layer to Function directly</h2>
              <h4 className="text-white">Using Lambify ARN url</h4>
            </div>
            {/* <div className="bg-yellow-500 rounded h-40 p-4 cursor-pointer">
              <h2 className="font-bold text-white text-xl">Download file</h2>
              <h4 className="text-white">Download file</h4>
            </div> */}
          </div>

          <div className="mb-20">
            <h2 className="font-bold text-xl mb-1">Create new layer in your AWS</h2>
            <p className="text-gray-500 mb-4">Download a layer from Lambify and upload the file to your AWS Lambda Layer.</p>

            <h4 className="flex items-center font-bold">
              <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-500 mr-2">1</div>
              Copy S3 URL or Download a file
            </h4>
            <p className="text-gray-500 pl-12 mb-4">
              Browse a layer you like, on Lambify, then click <b>"S3"</b> or <b>"Download"</b> to save the Layer address or the file.
            </p>
            <h4 className="flex items-center font-bold mb-2">
              <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-500 mr-2">2</div>
              Create new layer from downloaded Lambify layer
            </h4>
            <p className="text-gray-500 pl-12">
              Go to <b>AWS Console</b>, then navigate to <b>AWS Lambda</b>. After that, choose the <b>Layers</b>, and click <b>"Create layer"</b>.
            </p>
            <img className="w-3/4 m-4 border ml-12 mb-8" src={AWSLayers} alt="" />
            <p className="text-gray-500 pl-12">Enter Layer detail. After that upload the downloaded file or paste the S3 URL, which received from Lambify.<br /></p>
            <img className="w-1/2 m-4 border ml-12 mb-8" src={AWSCreateNewLayer} alt="" />
            <p className="text-gray-500 pl-12">
              🎉 Tada! Layer is now ready to use!
            </p>
          </div>

          <div>
            <h2 className="font-bold text-xl mb-1">Add Layer to AWS Lambda Function directly <span className="text-red-400">(Coming soon)</span></h2>
            <p className="text-gray-500 mb-4">AWS allow us to use custom AWS Lambda Layer from other sources, include Lambify, using AWS ARN.</p>

            <h4 className="flex items-center font-bold mb-2">
              <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-500 mr-2">1</div>
              Copy ARN from Lambify
            </h4>
            <p className="text-gray-500 pl-12">
              Browse a layer you like, on Lambify, then click <b>"ARN"</b> to copy AWS ARN.
            </p>
            <img className="w-1/2 m-4 border ml-12 mb-8" src={LambifyARNImage} alt="" />

            <h4 className="flex items-center font-bold mb-2">
              <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-500 mr-2">2</div>
              Attach layer to your AWS Lambda Fuction
            </h4>
            <p className="text-gray-500 pl-12">
              Go to <b>AWS Console</b>, then navigate to <b>AWS Lambda</b>. After that, choose the <b>Function</b>, and click <b>"Add a layer"</b>.
            </p>
            <img className="w-1/2 m-4 border ml-12 mb-8" src={AWSAttachLayer} alt="" />

            <p className="text-gray-500 pl-12">
              Choose "Specify an ARN" and paste a copied ARN from Lambify.
            </p>
            <img className="w-1/2 m-4 border ml-12 mb-4" src={ARNImage} alt="" />
            <p className="text-gray-500 pl-12">
              🎉 Tada! Function is now ready to use!
            </p>
          </div>


        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Documentation;
