import { useState } from 'react';
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';

import Logo from './images/logo512.png';

const Header = () => {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState<boolean>(false);

  const onClickHamburgerButton = () => {
    setIsShowMobileMenu((previousValue: boolean) => !previousValue);
  }

  return (
    <div>
      <header className="flex justify-between py-4 mb-2">
        <Link to="/" className="flex items-center" onClick={() => setIsShowMobileMenu(false)}>
          <img src={Logo} className="w-12 mr-2" alt="lambify logo" />
          <h1 className="font-bold text-2xl text-gray-800 md:text-4xl">Lambify<b className="text-yellow-600">.</b></h1>
        </Link>

        <div className="flex flex-row justify-center items-center hidden md:flex">
          <Link to="/" className="font-bold mr-8">Home</Link>
          <Link to="/docs" className="font-bold mr-8">Documentation</Link>
          <Link to="/build-a-layer" className="rounded-xl bg-gray-900 px-8 py-2 text-white font-bold shadow-lg">Build a layer</Link>
        </div>

        <div className="flex lg:hidden">
          <div className="border-2 border-black rounded flex items-center justify-center w-12 h-12">
            <FaBars size="20" onClick={onClickHamburgerButton} />
          </div>
        </div>
      </header>

      {isShowMobileMenu ? (
        <div className="w-full h-screen px-2 pt-4 flex flex-col">
          <Link to="/" className="font-bold text-2xl mb-2 underline">Home</Link>
          <Link to="/docs" className="font-bold text-2xl mb-8 underline">Documentation</Link>
          <Link
            to="/build-a-layer"
            className="rounded-xl bg-gray-900 px-8 py-2 w-56 text-xl text-white font-bold shadow-lg flex justify-center"
          >
            Build a layer
          </Link>
        </div>
      ) : (null)}
    </div>
  );
}

export default Header;
