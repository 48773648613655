import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Footer from '../../components/footer';
import Header from '../../components/header';
import { API_URL } from '../../config/app';

const BuildLayer= () => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [layerRuntime, setLayerRuntime] = useState<string>('python3.8');
  const [layerPackages, setLayerPackages] = useState<string[]>([]);
  const [layerPackagesString, setLayerPackagesString] = useState<string>('');
  const [layerName, setLayerName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const buildLayer = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${API_URL}/builds`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          runtime: layerRuntime,
          packages: layerPackages,
          name: layerName,
        })
      }
    );
    const data = await response.json();

    if (response.status === 200) {
      history.push(`/layers/${data.build_id}`);
    } else {
      setErrorMessage('Invalid package name');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    let data = layerPackagesString.replace(/\s/g, '').split(',')
    data = data.filter(value => value !== '');
    setLayerPackages(data);
  }, [layerPackagesString]);

  return (
    <div className="container mx-auto px-8">
      <Header />

      <div className="flex flex-row">
        <div className="hidden md:flex w-1/2 rounded-lg bg-gray-800 h-80 py-80 mr-16">
        </div>

        <div className="w-full md:w-1/2 pt-12">
          <h1 className="text-4xl font-bold mb-8">Create your <span className="text-yellow-600">AWS Lambda</span> Layer!</h1>

          {errorMessage ? (
            <div className="border-2 border-red-500 rounded p-4 mb-8 font-bold text-red-500 bg-white">
              {errorMessage}
            </div>
          ) : (null)}

          <h3 className="flex items-center text-xl text-gray-700 font-bold mt-4 mb-4">
            <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-600 mr-2">1</div>
            Choose the runtime (languages)
          </h3>
          <div className="mb-8">
            <select
              className="bg-white w-full p-4 border-2 rounded-md"
              value={layerRuntime}
              onChange={e => setLayerRuntime(e.target.value)}
            >
              <option value="python3.8">Python 3.8</option>
              <option value="python3.7">Python 3.7</option>
              <option value="python3.6">Python 3.6</option>
              <option value="python2.7">Python 2.7</option>
              <option value="nodejs14.x" disabled>Node.js 14.x</option>
              <option value="nodejs12.x" disabled>Node.js 12.x</option>
            </select>
          </div>


          <h3 className="flex items-center text-xl text-gray-700 font-bold mb-4">
            <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-600 mr-2">2</div>
            Enter the packages
          </h3>
          <div className="mb-8">
            <input
              className="bg-white w-full p-4 border-2 rounded-md"
              placeholder="Ex: requests==2.25.0, pillow, urllib3"
              value={layerPackagesString}
              onChange={e => setLayerPackagesString(e.target.value)}
              />
            <p className="text-sm text-gray-400 mt-1 pl-2">Separate packages with comma (,) and specific version with double equal (==)</p>
          </div>

          <h3 className="flex items-center text-xl text-gray-700 font-bold mb-4">
            <div className="flex flex-col justify-center items-center rounded-full text-white w-8 h-8 bg-yellow-600 mr-2">3</div>
            Name the layer
          </h3>
          <div className="mb-4">
            <input
              className="bg-white w-full p-4 border-2 rounded-md"
              placeholder="Name will be displayed in layers "
              value={layerName}
              onChange={e => setLayerName(e.target.value)}
            />
          </div>

          <p className="text-gray-400 text-sm mb-2">The layer will expired in 30 days. To extended, please signin.</p>
          <button
            onClick={buildLayer}
            className="bg-yellow-600 text-white p-2 px-8 font-bold text-lg rounded-md shadow-lg disabled:opacity-50"
            disabled={isLoading || (layerName === '' || layerPackages === [] || layerRuntime === '' )}
          >
            {isLoading ? 'Building...' : 'Build now'}
          </button>

        </div>
      </div>

      <Footer />
    </div>
  );
}
export default BuildLayer;
